import {useReducer} from 'react'
import {getData, sendRequestAuth} from 'api/api'
import {useProductAction, useProductInitialState, useProductReducer} from '../provider/_reducer'
import {useEffect} from 'react'
import {getListProductGroup} from 'api/url'
import config from "../../../config";

const useProductGroup = () => {
   const [state, dispatch] = useReducer(useProductReducer, useProductInitialState)
   useEffect(() => {
         fetchData(state.search)
   }, [])
   const fetchData = async (q) => {
      try {
         const res = await getData(getListProductGroup(q))
         let arr = []
         if (res.data.success) {
            if (res.data.data) {
               res.data.data.map(item => {
                  if (+item?.parent === 0)
                     arr.push({item: item.title, id: item.id})
               })
            }
            dispatch({type: useProductAction.CATEGORY_LIST, payload: arr})
            dispatch({type: useProductAction.ARR_CATEGORY, payload: arr})
            const ProductGroup = res?.data
            dispatch({
               type: useProductAction.TABLE_UPDATE_DISPLAY_LIST,
               payload: {
                  list: ProductGroup?.data,
                  listDefault: ProductGroup?.data,
                  loading: false
               }
            })
            dispatch({
               type: useProductAction.TABLE_UPDATE_PAGINATION,
               payload: {
                  active: 0,
                  amount: ProductGroup?.meta?.per_page,
                  total: Math.ceil(ProductGroup?.meta?.total / ProductGroup?.meta?.per_page),
                  totalItems: ProductGroup?.meta?.total,
               }
            })
         }
      } catch (er) {
         console.log(er);
      }
   }


   const querySearch = {
      type: 'book',
      per_page: state?.table?.pagination?.amount || 20,
      start: (state?.table?.pagination?.active * state?.table?.pagination?.amount) || 0,
      keyword: state.filter?.search?.value || '',
      status: state.filter?.status?.value?.value || '',
      parent_id: state.filter.parentCategory.value?.id || ''
   }

   const handleAmountChange = async n => {
      const qs = {...querySearch, per_page: n, start: 0}
      let queryString = '?'
      let i = 0
      for (const [key, value] of Object.entries(qs)) {
         queryString += `${i > 0 ? '&' : ''}${key}=${value}`
         i++
      }
      fetchPrincipalByFilter(queryString)
   }

   const handlePageChange = async page => {
      const amount = state?.table?.pagination?.amount || 20
      const qs = {
         ...querySearch,
         per_page: state?.table?.pagination?.amount,
         start: page * amount
      }
      let queryString = '?'
      let i = 0
      for (const [key, value] of Object.entries(qs)) {
         queryString += `${i > 0 ? '&' : ''}${key}=${value}`
         i++
      }
      fetchPrincipalByFilter(queryString)
   }

   const fetchPrincipalByFilter = async (qs) => {
      dispatch({
         type: useProductAction.TABLE_LOADING_DISPLAY,
         payload: true
      })
      const response = await Promise.all([
         sendRequestAuth('get', `${config.API}/product-category/categories${qs}`)
      ])
      if (response[0]?.data?.success) {
         dispatch({
            type: useProductAction.TABLE_LOADING_DISPLAY,
            payload: false
         })
         const books = response[0]?.data
         dispatch({
            type: useProductAction.TABLE_UPDATE_DISPLAY_LIST,
            payload: {
               list: books?.data,
               listDefault: books?.data,
               loading: false
            }
         })
         dispatch({
            type: useProductAction.TABLE_UPDATE_PAGINATION,
            payload: {
               active: Math.ceil(books?.meta?.start / books?.meta?.per_page),
               amount: books?.meta?.per_page,
               total: Math.ceil(books?.meta?.total / books?.meta?.per_page),
               totalItems: books?.meta?.total,
            }
         })
      }
   }
   return {
      provider: {state, dispatch},
      pagination: {
         onAmountChange: handleAmountChange,
         onPageChange: handlePageChange,
      }
   }
}
export default useProductGroup