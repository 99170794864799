import {useCallback, useEffect, useState} from "react"
import {useContext} from "react"
import {OrderContext} from '../provider/_context'
import {useTranslation} from "react-i18next";
import useAlert from '../../../hook/useAlert'
import {sendRequestAuth} from '../../../api/api'
import config from '../../../config'
import {removeAcent} from '../../../common/fieldText/_functions'
import {orderActions} from "../provider/_reducer";
import useOrderFilterForm from "./useOrderFilterForm";
import {debounce} from "@mui/material";

export const useEditOrderModal = () => {
   const {t} = useTranslation()
   const {pageState, pageDispatch} = useContext(OrderContext)
   const [animate, setAnimate] = useState(false)
   const {showAlert} = useAlert()
   const {editOrderModal} = pageState

   const {dataSelect} = editOrderModal

   // orderActions.UPDATE_CUSTOMER_MODAL

   const getContactList = async (cardCode) => {
      const response = await sendRequestAuth('get', `${config.API}/order/filter/contacts/${cardCode}`)
      if (response?.data?.length > 0) {
         pageDispatch({
            type: orderActions.MODAL_CONTACT_UPDATE,
            payload: {
               list: response.data,
               listOrigin: response.data,
            }
         })
      }
   }
   const getAddressList = async (cardCode) => {
      const response = await sendRequestAuth('get', `${config.API}/order/filter/addresses/${cardCode}`)
      if (response?.data?.length > 0) {
         pageDispatch({
            type: orderActions.MODAL_ADDRESS_UPDATE,
            payload: {
               list: response.data,
               listOrigin: response.data,
            }
         })
      }
   }

   const getSchoolList = async data => {
      const response = await sendRequestAuth('get', `${config.API}/order/filter/schools`)
      if (response?.data?.length > 0) {
         pageDispatch({
            type: orderActions.MODAL_SCHOOL_UPDATE,
            payload: {
               list: response.data,
               listOrigin: response.data,
            }
         })
      }
   }
   const updateEditOrderModal = (data, open) => {
      pageDispatch({
         type: orderActions.UPDATE_EDIT_ORDER_MODAL,
         payload: {
            detail: data,
            open_modal: open
         }
      })

      pageDispatch({
         type: orderActions.MODAL_CONTACT_UPDATE,
         payload: {
            value: data,
         }
      })
      if (!!!data?.card_code) {
         pageDispatch({
            type: orderActions.MODAL_SCHOOL_UPDATE,
            payload: {
               value: {
                  school_name: data?.customer_school,
                  school_code: data?.school?.school_code || ''
               },
            }
         })
         pageDispatch({
            type: orderActions.MODAL_ADDRESS_STRANGE_UPDATE,
            payload: {
               value: data?.customer_address ? data.customer_address : '',
            }
         })
      } else {
         pageDispatch({
            type: orderActions.MODAL_CONTACT_UPDATE,
            payload: {
               value: {
                  cntct_code: data?.cntct_code || '',
                  contact_name: data?.contact_name || '',
                  contact_phone: data?.customer_phone || ''
               }
            }
         })
         pageDispatch({
            type: orderActions.MODAL_ADDRESS_UPDATE,
            payload: {
               value: {
                  address: data?.customer_address,
                  ship_to_code: data?.ship_to_code || 0,
               },
            }
         })
      }
   }
   // ===== ===== ===== ===== =====
   // Address Strange
   // ===== ===== ===== ===== =====
   const addressStrangeValue = dataSelect.addressStrange.value

   const handleAddressStrangeChange = async (data) => {
      pageDispatch({
         type: orderActions.MODAL_ADDRESS_STRANGE_UPDATE,
         payload: {value: data},
      })
   }
   // ===== ===== ===== ===== =====
   // contact
   // ===== ===== ===== ===== =====
   const contactActiveValue = dataSelect.contact.activeValue
   const contactKeyword = dataSelect.contact.keyword
   const contactList = dataSelect.contact.list
   const contactListOrigin = dataSelect.contact.listOrigin
   const contactValue = dataSelect.contact.value

   const handleContactChange = async (data) => {
      pageDispatch({
         type: orderActions.MODAL_CONTACT_UPDATE,
         payload: {value: data},
      })

   }

   const handleContactKeywordChange = data => {
      const formatDataValue = data?.value
         ? removeAcent(data?.value?.toLowerCase())
         : ''

      const contactListData = contactListOrigin.filter(item => {
         const formatNameItem = item?.contact_name
            ? removeAcent(item.contact_name.toLowerCase())
            : ''
         if (formatNameItem.includes(formatDataValue)) return true
         return false
      })
      pageDispatch({
         type: orderActions.MODAL_CONTACT_KEYWORD_UPDATE,
         payload: {
            keyword: data?.value || '',
            list: contactListData,
         },
      })
   }


   // ===== ===== ===== ===== =====
   // Address
   // ===== ===== ===== ===== =====
   const addressActiveValue = dataSelect.address.activeValue
   const addressKeyword = dataSelect.address.keyword
   const addressList = dataSelect.address.list
   const addressListOrigin = dataSelect.address.listOrigin
   const addressValue = dataSelect.address.value

   const handleAddressChange = data => {
      pageDispatch({
         type: orderActions.MODAL_ADDRESS_UPDATE,
         payload: {value: data},
      })

      pageDispatch({
         type: orderActions.UPDATE_VALIDATE_CUSTOMER_MODAL,
         payload: {address: ''},
      })
   }

   const handleAddressKeywordChange = data => {
      const formatDataValue = data?.value
         ? removeAcent(data?.value?.toLowerCase())
         : ''

      const addressListData = addressListOrigin.filter(item => {
         const formatNameItem = item?.address
            ? removeAcent(item.address.toLowerCase())
            : ''
         if (formatNameItem.includes(formatDataValue)) return true
         return false
      })

      pageDispatch({
         type: orderActions.MODAL_ADDRESS_KEYWORD_UPDATE,
         payload: {
            keyword: data?.value || '',
            list: addressListData,
         },
      })
   }
   // ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== =====
   // ===== ===== ===== ===== =====
   // school
   // ===== ===== ===== ===== =====
   const schoolKeyword = dataSelect.school.keyword
   const schoolList = dataSelect.school.list
   const schoolListOrigin = dataSelect.school.listOrigin
   const schoolValue = dataSelect.school.value


   const getListSchool = async (keyword) => {
      const response = await sendRequestAuth(
         "get",
         `${config.API}/order/filter/schools?keyword=${keyword}`,
         ""
      );
      if (response.data.length > 0)
         pageDispatch({
            type: orderActions.MODAL_SCHOOL_UPDATE,
            payload: {
               list: response.data,
            }
         })
   };

   const debounceMethodKeywordChange = useCallback(
      debounce((value) => {
         // setLoading(true);
         getListSchool(value);
      }, 500),
      []
   );

   const handleSchoolKeywordChange = (data) => {
      pageDispatch({
         type: orderActions.MODAL_SCHOOL_UPDATE,
         payload: {
            value: {
               school_name: data,
               school_code: ''
            }
         },
      })

      pageDispatch({
         type: orderActions.MODAL_ADDRESS_STRANGE_UPDATE,
         payload: {
            value: '',
         }
      })
      debounceMethodKeywordChange(data)
   }

   const handleSchoolChange = data => {
      pageDispatch({
         type: orderActions.MODAL_SCHOOL_UPDATE,
         payload: {value: data},
      })
      pageDispatch({
         type: orderActions.MODAL_ADDRESS_STRANGE_UPDATE,
         payload: {
            value: (!!data?.center_name ? data?.center_name : '') + (!!data?.province_name ? ', ' + data?.province_name : '') + (!!data?.region_name ? ', ' + data?.region_name : ''),
         }
      })
   }
   // ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== ===== =====

   const handleClose = () => {
      if (!pageState.change_modal) {
         setAnimate(true)
         setTimeout(() => {
            pageDispatch({type: orderActions.OPEN_MODAL, payload: false})
            setAnimate(false)
         }, 300)
         pageDispatch({type: orderActions.DISABLE_SELECT, payload: false})
      } else {

         pageDispatch({type: orderActions.MODAL_CONFIRM, payload: true})
      }
   }
   const checkBeforeSubmit = [
      pageState.valid?.filde_name?.status,
      pageState.valid?.filde_parent?.status,
   ].includes(true)

   const {functions} = useOrderFilterForm()
   const handleAccept = async () => {
      const {detail} = editOrderModal
      const urlUpdate = !!detail?.card_code ? `${config.API}/order/update-sap-order/${detail?.id}` : `${config.API}/order/update/${detail?.id}`
      const dataRequest = !!detail?.card_code ?
         {
            "cntct_code": contactValue?.cntct_code,
            "contact_name": contactValue?.contact_name,
            "contact_phone": contactValue?.contact_phone,
            "ship_to_code": addressValue?.ship_to_code,
            "address": addressValue?.address
         }
         : {
            "school_id": schoolValue?.id,
            "school": schoolValue?.school_name,
            "address": addressStrangeValue
         }
      const response = await sendRequestAuth('post', urlUpdate, dataRequest)
      if (!!response?.data?.success) {
         setTimeout(() => {
            pageDispatch({type: orderActions.OPEN_MODAL, payload: false})
            setAnimate(false)
         }, 300)

         pageDispatch({
            type: orderActions.MODAL_CONTACT_UPDATE,
            payload: {value: null},
         })
         pageDispatch({
            type: orderActions.MODAL_ADDRESS_UPDATE,
            payload: {value: null},
         })
         functions.applyOrderOtherFilter()
         showAlert({
            type: 'success',
            content: response?.data?.message
         })
      } else {
         showAlert({
            type: 'danger',
            content: response?.data?.message
         })
      }

   }

   const handleCancelConfirm = () => {
      pageDispatch({type: orderActions.MODAL_CONFIRM, payload: false})
   }
   const handleAcceptConfirm = () => {
      pageDispatch({type: orderActions.MODAL_CONFIRM, payload: false})
      pageDispatch({type: orderActions.CHANGE_MODAL, payload: false})
      setAnimate(true)
      pageDispatch({
         type: orderActions.MODAL_CONTACT_UPDATE,
         payload: {value: null},
      })
      pageDispatch({
         type: orderActions.MODAL_ADDRESS_UPDATE,
         payload: {value: null},
      })
      setTimeout(() => {
         pageDispatch({type: orderActions.OPEN_MODAL, payload: false})
         setAnimate(false)
      }, 300)
      pageDispatch({type: orderActions.DISABLE_SELECT, payload: false})
   }


   return {
      detail: pageState?.editOrderModal?.detail,
      contact: {
         keyword: contactKeyword,
         list: contactList,
         value: contactValue,
         onChange: handleContactChange,
         onKeywordChange: handleContactKeywordChange,
      },
      addressStrange: {
         value: addressStrangeValue,
         onChange: handleAddressStrangeChange,
      },
      address: {
         keyword: addressKeyword,
         list: addressList,
         listOrigin: addressListOrigin,
         value: addressValue,
         onChange: handleAddressChange,
         onKeywordChange: handleAddressKeywordChange,
      },
      school: {
         keyword: schoolKeyword,
         list: schoolList,
         listOrigin: schoolListOrigin,
         value: schoolValue,
         onChange: handleSchoolChange,
         onKeywordChange: handleSchoolKeywordChange,
      },
      modal: {
         handleAccept,
         handleClose,
         checkBeforeSubmit,
      },
      confirm: {
         handleCancelConfirm,
         handleAcceptConfirm,
      },
      animate,
      methods: {
         getAddressList,
         getContactList,
         getSchoolList,
         updateEditOrderModal
      }
   }
}