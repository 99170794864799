import {Popover} from '@mui/material'
import {THEME_COLORS} from 'common/theme/_colors'
import {THEME_SEMANTICS} from 'common/theme/_semantics'
import {ORDER_TABLE_ROW_MENU_POPOVER} from 'Pages/orderBook/interfaces/_constants'
import {useContext, useState} from 'react'
import styled from 'styled-components'
import {checkPermission} from "../../../../Component/NavBar/until";
import PopupNotPermission from "../../../permision/popupNotPermission";
import {GLOBAL_ICONS} from '../../../../interface/icon'

export const RowMenuPopover = ({
                                  id,
                                  shippingStatus, dataOrder,
                                  onActionClick,
                               }) => {
   const [anchorEl, setAnchorEl] = useState(null)
   const handleClick = e => {
      e.preventDefault()
      e.stopPropagation()
      setAnchorEl(e.currentTarget)
   }

   const handleClose = () => setAnchorEl(null)

   const open = Boolean(anchorEl)
   const idData = open ? id : undefined

   const actionList = ORDER_TABLE_ROW_MENU_POPOVER[shippingStatus]

   const validAddPermission = checkPermission('order', 'add')
   const validEditPermission = checkPermission('order', 'edit')
   const validCancelPermission = checkPermission('order', 'cancel')
   const validCancelDeliveryPermission = checkPermission('order', 'cancel_delivery')
   const validPrintPermission = checkPermission('order', 'print')
   const validReportPermission = checkPermission('order', 'report_customer')
   const validShippingPermission = checkPermission('order', 'send_delivery')
   // const [popupPermission, setPopupPermission] = useState(false)

   const handleItemClick = type => {
      if ((type === 'cancel-order' && !validCancelPermission)
         || (type === 'cancel-shipping' && !validCancelDeliveryPermission)
         || (type === 'edit' && !validEditPermission)
         || (type === 'copy' && !validAddPermission)
         || (type === 'print' && !validPrintPermission)
         || (type === 'report' && !validReportPermission)
         || (type === 'shipping' && !validShippingPermission)) {
         // setPopupPermission(true)
      } else {
         onActionClick(type)
      }
      handleClose()
   }
   // const closePopupPermission = _ => setPopupPermission(false)
   return (
      <>
         {/*{popupPermission && <PopupNotPermission cID={idData} cAnchorEl={anchorEl} handleClose={() => closePopupPermission()}/>}*/}
         <StyledIconToggle
            aria-describedby={idData}
            variant="contained"
            style={{cursor: 'pointer'}}
            onClick={handleClick}
         >
            {GLOBAL_ICONS.manipulation}
         </StyledIconToggle>
         {open && actionList?.length > 0 && (
            <Popover
               id={idData}
               className="common-popover"
               open={true}
               anchorEl={anchorEl}
               onClose={handleClose}
               anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
               }}
               transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
               }}
            >
               <StyledRowMenuPopover>
                  {actionList.map(item => {
                        if (!!dataOrder?.card_code) {
                           // khách sap
                           if ((  !['move-sap-order','cancel-order',"completed","move-distributor-order"].includes(item.action))
                              || dataOrder?.order_type === 'uncategorized'
                              || (dataOrder?.order_process_status === 'failed' && item.action === 'move-sap-order'))
                              return (
                                 <li
                                    key={item.id}
                                    className="row-menu-popover__item"
                                    data-danger={item?.isDanger}
                                    onClick={() => handleItemClick(item.action)}
                                 >
                                    {item.icon} <span>{item.name}</span>
                                 </li>
                              )
                        } else {
                           // khách tự do
                           if ((item.action !== 'move-sap-order' && item.action !== 'move-distributor-order') || (dataOrder?.order_type === 'uncategorized' && (item.action !== 'move-sap-order')))
                              return (
                                 <li
                                    key={item.id}
                                    className="row-menu-popover__item"
                                    data-danger={item?.isDanger}
                                    onClick={() => handleItemClick(item.action)}
                                 >
                                    {item.icon} <span>{item.name}</span>
                                 </li>
                              )
                        }
                     }
                  )}
               </StyledRowMenuPopover>
            </Popover>
         )}
      </>
   )
}

const StyledRowMenuPopover = styled.ul`
                     width: 226px;
                     padding: 8px 12px;

                     .row-menu-popover {
                     &__item {
                     height: 36px;

                     display: flex;
                     align-items: center;

                     color: #00081d;
                     font-size: 14px;
                     font-weight: 400;
                     line-height: 20px;

                     transition: color 0.25s;

                     cursor: pointer;

                     &[data-danger='true'] {
                     &:hover {
                     color: ${THEME_SEMANTICS.failed};

                     svg {
                     color: ${THEME_SEMANTICS.failed};

                     path[stroke] {
                     stroke: ${THEME_SEMANTICS.failed};
                     }

                     path[fill] {
                     fill: ${THEME_SEMANTICS.failed};
                     }
                     }
                     }
                     }

                     &:hover {
                     color: ${THEME_COLORS.primary_300};

                     svg {
                     color: ${THEME_COLORS.primary_300};

                     path[stroke] {
                     stroke: ${THEME_COLORS.primary_300};
                     }

                     path[fill] {
                     fill: ${THEME_COLORS.primary_300};
                     }
                     }
                     }

                     svg {
                     width: 18px;
                     height: 18px;

                     margin-right: 10px;
                     }
                     }
                     }
                     `

const StyledIconToggle = styled.i`
                     position: relative;
                     z-index: 1;

                     &:hover {
                     svg {
                     color: ${THEME_COLORS.primary_300};

                     path[stroke] {
                     stroke: ${THEME_COLORS.primary_300};
                     }

                     path[fill] {
                     fill: ${THEME_COLORS.primary_300};
                     }
                     }
                     }
                     `
